// Things to change

// var host = "isuog2021-c82822.uatnewepresenter.espeaker.com.au"; //'isuog2020-c82822.epresenter.com.au'; // For localhost //hno2022-c17516
// var host = "posterpdf-c86609.uatnewepresenter.espeaker.com.au"
//var host = "felasa2022-c17516.epresenter.com.au"; //"epns2022-c17516.epresenter.com.au";
// var host = "event0808-c86609.uat.espeaker.com.au";
var host = window.location.host; // For production & UAT

// Things to change

localStorage.setItem("hostname", host);
var protocol = window.location.protocol + "//";
var externalFileLink = protocol + host + "/";

// Print
console.log("Protocol: ", protocol);
console.log("Host: " + window.location.host);
console.log("External URL: " + externalFileLink);

// export const myConfig = {
//   http: protocol,
//   apiUrl: protocol + "localhost/epresenternew/backend/",
//   importantData: "",
//   envExtention: "",
//   externalFileUrl:
//     protocol + "isuog2021-c82822.uatnewepresenter.espeaker.com.au/",
//   s3HostName: "https://d64l9z9mn7e7n.cloudfront.net/",
//   s3EventId: 662,
// }; //Local

// export const myConfig = {
//   http: protocol,
//   apiUrl: protocol + "api.uatnewepresenter.espeaker.com.au/backend/",
//   envExtention: "",
//   importantData: "",
//   externalFileUrl: externalFileLink,
//   s3HostName: "https://d64l9z9mn7e7n.cloudfront.net/", // As of now applicable for event id 662 (Felasa)
//   s3EventId: 612,
// }; //UAT

export const myConfig = {
  http: protocol,
  apiUrl: protocol + "api.epresenter.com.au/backend/",
  envExtention: "",
  importantData: "",
  externalFileUrl: externalFileLink,
  s3HostName: "https://d17rzk62f0z7oy.cloudfront.net/", // As of now applicable for event id 662 (Felasa)
  s3EventId: 662,
}; //Live
