import * as actions from "../actions/actionTypes"

const authorReducer = (state = {}, action) => {
    switch(action.type){
        
        case actions.SETAUTHORS:
            return {
                authors: action.payload
            };

        default:
            return state;
    }

}

export default authorReducer;