import applyFilterReducer from "./applyFilter";

import themeReducer from "./themes";
import authorReducer from "./authors";
import eventSettingReducer from "./eventSetting";
import programDateReducer from "./programdates";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
    filters: applyFilterReducer,
    themes: themeReducer,
    authors: authorReducer,
    eventsettings: eventSettingReducer,
    programdates: programDateReducer
});

export default rootReducer;