import * as actions from "../actions/actionTypes"

const programDateReducer = (state = {}, action) => {
    switch(action.type){
        
        case actions.SETPROGRAMDATES:
            return {
                programdates: action.payload
            };

        default:
            return state;
    }

}

export default programDateReducer;