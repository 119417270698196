import React, { Component } from "react";
import css from "./footer.module.scss";
import "./footer.scss";
import { myConfig } from "../../config";

const Footer = (props) => {
  // 	console.log(props.whiteLabel.logoImage);
  var hostname = localStorage.getItem("hostname");
  //   var whitelabel = JSON.parse(localStorage.getItem("whitelabel"));
  var whitelabel = props.whiteLabel;
  return (
    <div className={css.footerRoot}>
      <div
        className={css.footerrow}
        style={{
          background: props.backgroundColor,
          display: "flex",
        }}
      >
        {whitelabel.logoImage || whitelabel.brandName ? (
          <>
            {whitelabel.logoImage ? (
              <img
                className={css.footerlogo}
                src={myConfig.http + hostname + "/" + whitelabel.logoImage}
              />
            ) : (
              ""
            )}

            <span className="footerText">
              {whitelabel.brandName ? <>{whitelabel.brandName}</> : ""}
            </span>
          </>
        ) : (
          <>
            <img className={css.footerlogo} src="/images/ePresenter.png" />
          </>
        )}

        <span className="poweredbySpan" style={{ marginLeft: "auto" }}>
          Powered by <span class="">evexus</span>
        </span>
      </div>
    </div>
  );
};

export default Footer;
