import React, {useState, useEffect} from 'react'
import css from "./header.module.scss";
import { myConfig } from "../../config";
import { AnimateOnChange } from 'react-animation';
import "./header.scss";
import './react-owl-carousel2/style.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

const options = {
    items: 1,
    nav: false,
    rewind: true,
    autoplay: true
};

const Header = (props) => {

	var eventName = localStorage.getItem('enm');
	let bImage = props.bannerImage.split("data/")[1];
	let bannerImage = myConfig.externalFileUrl+bImage;
	var textBackgroundColor = '';
	if (props.headerDesign == 0) {
		textBackgroundColor = props.textBackgroundColor;
	}
	var sponsorByText = '';
	if (props.sponsorByText) {
		sponsorByText = '' + props.sponsorByText;
	}
	var styleHead = {};
	if (props.headerDesign == 1) {
		styleHead = {
			background: textBackgroundColor, 
			backgroundColor: props.imageBackgroundColor
		}
	} else {
		styleHead = {
			background: textBackgroundColor
		}
	}
	
	
	return (
		<>
			<title>{eventName}</title>
			<div className={css.headerRoot} style={styleHead}>
				{ (props.headerDesign == 1) ? 
					<>
						<div
							className={css.headbg}
							// style={{
							// 	background: "url("+bannerImage+")",
							// 	backgroundRepeat: "no-repeat",
							// }}
						>
							<img src={bannerImage} />
							{/* <span style={{"font-size": "20px"}}>{sponsorByText}</span> */}
						</div>
						
						
					</>
				:
					<>
						<div className="headerWithText">
							{props.headerText != '' ? 
								<h2 style={{color:props.textColor}}>{props.headerText}</h2>
							: '' }
							<span style={{"font-size": "20px"}}>{sponsorByText}</span>
						</div>
					</>
				}
			</div>
		</>
	)
}

export default Header;