import React, { Component } from "react";
import "./posterskeleton.scss";

const PosterSkeleton = () => {
  
    return (
        <template className="card skeleton">
            <div className="card__image loading"></div>
            <div className="card__title loading"></div>
                <div className="card__title2 loading"></div>
            <div className="card__description loading"></div>
        </template>
    );
}

export default PosterSkeleton;