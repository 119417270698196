import * as actions from "./actionTypes";

// Set Filters
export const chnageFilterData = (
  searchData = "",
  author = "",
  posterDate = null,
  posterRoom = null,
  posterSessionName = ""
) => {
  return {
    type: actions.SETFILTERS,
    payload: {
      searchData: searchData,
      author: author,
      posterDate: posterDate,
      posterRoom: posterRoom,
      posterSessionName: posterSessionName,
    },
  };
};

// Set themes
export const setThemesData = (themes) => {
  return {
    type: actions.SETTHEMES,
    payload: {
      themes,
    },
  };
};

// Set Authors
export const setAuthorsData = (authors) => {
  return {
    type: actions.SETAUTHORS,
    payload: {
      authors,
    },
  };
};

// Set Posters
export const setPostersData = (posters) => {
  return {
    type: actions.SETPOSTERS,
    payload: {
      posters,
    },
  };
};

// Set Poster Count
export const setPostersCountData = (posterscount) => {
  return {
    type: actions.SETPOSTERSCOUNT,
    payload: {
      posterscount,
    },
  };
};

// Set Poster Reviews
export const setPostersReviewsData = (posterreviews) => {
  return {
    type: actions.SETPOSTERREVIEWS,
    payload: {
      posterreviews,
    },
  };
};

// Set Event Settings
export const setEventSettingData = (eventsettings) => {
  return {
    type: actions.SETEVENTSETTING,
    payload: {
      eventsettings,
    },
  };
};

// Set Event Settings
export const setProgramDates = (programDates) => {
  return {
    type: actions.SETPROGRAMDATES,
    payload: {
      programDates,
    },
  };
};
