import * as actions from "../actions/actionTypes"

const applyFilterReducer = (state = {}, action) => {
    switch(action.type){
        
        case actions.SETFILTERS:
            return {
                filters: action.payload
            };

        default:
            return state;
    }

}

export default applyFilterReducer;