import "./App.css";
import "./main.scss";
import React, { useState, useEffect, Fragment } from "react";
import Header from "./components/Header/Header.js";
import Nav from "./components/Nav/Nav.js";
import Footer from "./components/Footer/Footer.js";
import Index from "./pages/IndexPage/Index.js";
import Poster from "./pages/PostersPage/Posters.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { myConfig } from "./config.js";
import { useSelector, useDispatch } from "react-redux";
import {
  setThemesData,
  setAuthorsData,
  setEventSettingData,
  setProgramDates,
} from "./actions/index";
import ScrollButton from "./components/scroll/scrollButton";
import FadeIn from "react-fade-in";
import $ from "jquery";

import OwlCarousel from "react-owl-carousel2";
import "./react-owl-carousel2/style.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Alert, Button } from "react-bootstrap";
const options = {
  items: 1,
  nav: false,
  rewind: true,
  autoplay: true,
};

function App() {
  // Cleaning Search Filters from local storage
  localStorage.removeItem("themeName");
  // End cleaning

  const dispatch = useDispatch();

  const axios = require("axios");
  var urlpath = window.location.pathname;
  var qs = require("qs");

  const [state, setState] = useState({});
  localStorage.setItem("screenSaverIterationCount", 0);
  const [eConfig, setEconfig] = useState({
    headerDesign: "",

    // Header Design 0
    headerText: "",
    textColor: "",
    textBackgroundColor: "",
    backgroundImage: "",
    navFooterColorBackground: "",

    // Header Design 1
    bannerImage: "",
    backgroundColor: "",
    navFooterColor: "",

    screenSaver: false,
    sponsorByText: "",
    navPosition: 0,
    posterKeywordsSearch: 0,
    homepage_version: 0,
    theme_background_image: 0,
    whiteLabel: {},
    appKey: "",
    appStatus: 0,
    appText: "",
    openDate: "",
    closeDate: "",
    appActive: 0,
    showSingleThemePoster: 0,
    defaultSort: "get-alphabetical",
  });

  //const [whitelabel, setWhiteLabel] = useState('');

  let data = qs.stringify({
    hostname: localStorage.getItem("hostname"),
  });

  // Get Config Variables //
  useEffect(() => {
    axios({
      method: "POST",
      url: myConfig.apiUrl + "getConfig.php",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        response = response.data;
        if (response) {
          // console.log(atob('NTYz'));
          localStorage.setItem("eid", response.eventId);
          localStorage.setItem("cid", response.clientId);
          localStorage.setItem("enm", response.eventName);
          let serializedState = JSON.stringify(response.eventSettings);
          let serializedWhiteLabelState = JSON.stringify(response.whiteLabel);
          localStorage.setItem("esettings", serializedState);
          localStorage.setItem("whitelabel", serializedWhiteLabelState);
          var settingsData = JSON.parse(serializedState);
          var whiteLabel = JSON.parse(serializedWhiteLabelState);
          var appActive = response.appActive;

          setEconfig({
            whiteLabel: whiteLabel,
            headerDesign: settingsData.headerDesign,
            headerText: settingsData.headerText,
            textColor: settingsData.textColor,
            textBackgroundColor: settingsData.textBackgroundColor,
            backgroundImage: settingsData.backgroundImage,
            navFooterColorBackground: settingsData.navFooterColorBackground,
            bannerImage: settingsData.bannerImage,
            backgroundColor: settingsData.backgroundColor,
            navFooterColor: settingsData.navFooterColor,
            screenSaver: settingsData.screenSaver,
            sponsorByText: settingsData.sponsorByText,
            sponsorImagePoster: settingsData.sponsorImagePoster,
            sponsorRepeatAfter: settingsData.sponsorRepeatAfter,
            startAfterIdleTime: settingsData.startAfterIdleTime, // Screen saver starts
            navPosition: settingsData.navPosition,
            posterKeywordsSearch: settingsData.posterKeywordsSearch,
            homepage_version: settingsData.homepage_version,
            textColorTheme: settingsData.textColorTheme,
            theme_background_image: settingsData.theme_background_image,
            appKey: settingsData.appKey,
            appStatus: settingsData.appStatus,
            appText: settingsData.appText,
            openDate: settingsData.openDate,
            closeDate: settingsData.closeDate,
            appActive: appActive,
            showSingleThemePoster: settingsData.showSingleThemePoster,
            defaultSort: settingsData.defaultSort,
          });
        }
        $("#fullScreenLoader").hide();
        dispatch(setEventSettingData(settingsData));
      })
      .catch(function (error) {
        console.log(error);
      });

    return () => {
      setState({});
    };
  }, []);

  const {
    whiteLabel,
    headerDesign,
    headerText,
    textColor,
    backgroundImage,
    navFooterColorBackground,
    bannerImage,
    backgroundColor,
    navFooterColor,
    screenSaver,
    navPosition,
    homepage_version,
    theme_background_image,
    startAfterIdleTime,
    textBackgroundColor,
    textColorTheme,
    sponsorByText,
    posterKeywordsSearch,
    appKey,
    appStatus,
    appText,
    openDate,
    closeDate,
    appActive,
    showSingleThemePoster,
    defaultSort,
  } = eConfig || {};

  // console.log(9999999999, eConfig);

  // Get Themes
  axios({
    method: "POST",
    url: myConfig.apiUrl + "themes.php",
    data: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
  })
    .then(function (response) {
      response = response.data || {};
      // console.log(666, response);
      if (response) {
        var serializedState = JSON.stringify(response);
        localStorage.setItem("state", serializedState);
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  // Screen Saver
  const [showScreensaver, setShowScreensaver] = useState(false);

  var IDLE_TIMEOUT = startAfterIdleTime * 60; //seconds
  var _idleSecondsTimer = null;
  var _idleSecondsCounter = 0;

  const changeDefOver = () => {
    _idleSecondsCounter = 0;
    window.location.reload();
    setShowScreensaver(false);
  };
  document.onclick = function () {
    _idleSecondsCounter = 0;
  };

  document.onmousemove = function () {
    _idleSecondsCounter = 0;
  };

  document.onkeypress = function () {
    _idleSecondsCounter = 0;
  };

  const CheckIdleTime = () => {
    _idleSecondsCounter++;
    var oPanel = document.getElementById("SecondsUntilExpire");
    if (oPanel) {
      oPanel.innerHTML = IDLE_TIMEOUT - _idleSecondsCounter + "";
    }
    if (_idleSecondsCounter >= IDLE_TIMEOUT) {
      _idleSecondsCounter = 0;
      window.clearInterval(_idleSecondsTimer);
      $("body").scrollTop(0);
      setShowScreensaver(true);
    }
  };
  if (screenSaver == 1) {
    _idleSecondsTimer = window.setInterval(CheckIdleTime, 1000);
  }
  // End Screen Saver

  // Getting data from local storage

  var lsState = localStorage.getItem("state");
  if (lsState === null) {
    lsState = undefined;
  } else {
    lsState = JSON.parse(lsState);
    dispatch(setThemesData(lsState.themes || {}));
    dispatch(setAuthorsData(lsState.authors || {}));
    dispatch(setProgramDates(lsState.programdates || {}));
    //setPosterIds(lsState.posterIds);
  }

  var eventId = atob(localStorage.getItem("eid"));
  var clientId = atob(localStorage.getItem("cid"));

  const [pageCount, setPageCount] = useState(1);
  const changePageCountState = (pageCount) => {
    setPageCount(pageCount);
  };

  localStorage.setItem("pageCount", 1);
  var backGroundStyle = "";
  var footerStyle = "";
  //console.log(headerDesign);
  if (headerDesign == 1) {
    backGroundStyle = {
      background: backgroundColor,
    };
    footerStyle = navFooterColor;
  } else {
    //let bgImage = backgroundImage; // For local
    let bgImage = backgroundImage.split("data/")[1]; // For production
    let backgrdImage = myConfig.externalFileUrl + bgImage;
    backGroundStyle = {
      backgroundImage: "url(" + backgrdImage + ")",
    };
    footerStyle = navFooterColorBackground;
  }

  var navTop = "";
  var navDown = "";
  var footerUp = "";
  var footerDown = "";
  if (navPosition == 0) {
    navTop = (
      <Nav
        posterKeywordsSearch={posterKeywordsSearch}
        homepage_version={homepage_version}
        backgroundColor={footerStyle}
        changePageCount={changePageCountState}
      />
    );
    footerUp = "";
    navDown = "";
    footerDown = (
      <Footer backgroundColor={footerStyle} whiteLabel={whiteLabel} />
    );
  } else {
    navTop = "";
    footerDown = "";
    footerUp = <Footer backgroundColor={footerStyle} whiteLabel={whiteLabel} />;
    navDown = (
      <Nav
        posterKeywordsSearch={posterKeywordsSearch}
        homepage_version={homepage_version}
        backgroundColor={footerStyle}
        navPosition={navPosition}
        changePageCount={changePageCountState}
      />
    );
  }

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "-" + mm + "-" + yyyy;
  var myDate = today.split("-");
  var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);

  var openDt = openDate.split("-");
  var newOpenDate = new Date(openDt[2], openDt[1] - 1, openDt[0]);

  var closeDt = closeDate?.split("-");
  var newCloseDate = new Date(closeDt[2], closeDt[1] - 1, closeDt[0]);
  // console.log(newDate.getTime());

  if (appStatus == 1) {
    localStorage.removeItem("key");
  }

  // const isActive =
  //   (newDate.getTime() > newOpenDate?.getTime() ||
  //     newDate.getTime() == newOpenDate?.getTime()) &&
  //   (closeDate
  //     ? newDate.getTime() < newCloseDate?.getTime() ||
  //       newDate.getTime() == newCloseDate?.getTime()
  //     : true)
  //     ? 1
  //     : 0;
  // console.log(
  //   333,
  //   newDate.getTime(),
  //   newOpenDate.getTime(),
  //   newCloseDate.getTime(),
  //   appActive,
  //   appStatus
  // );

  $("#alert").hide();

  useEffect(() => {
    if (eConfig.screenSaver == 1 && showScreensaver) {
      document.body.setAttribute("style", "overflow: hidden");
    } else {
      document.body.removeAttribute("style");
    }
  }, [eConfig.screenSaver, showScreensaver]);

  return (
    <Fragment>
      <>
        {/* {newDate.getTime() > newOpenDate?.getTime() ||
        newDate.getTime() ==
          newOpenDate?.getTime()(
            newCloseDate.getTime() != "NaN"
              ? newDate.getTime() < newCloseDate?.getTime() ||
                  newDate.getTime() == newCloseDate?.getTime()
              : true
          ) ? ( */}
        {1 == 1 ? (
          <>
            {appStatus != 0 && localStorage.getItem("key") != 1 ? (
              <>
                {appStatus == 1 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100vh",
                      fontSize: "20px",
                      fontWeight: "500",
                      backgroundColor: "#b3b2b1",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src="/images/ePresenter.png"
                      style={{ height: "10%", marginBottom: "10px" }}
                    />

                    <div>{$(appText).text()}</div>
                  </div>
                )}
                {appStatus == 2 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100vh",
                    }}
                  >
                    {localStorage.setItem("key", 0)}

                    <form>
                      <input
                        type="password"
                        placeholder="Enter Access Key"
                        style={{
                          border: "2px solid black",
                          borderRadius: "5px",
                          height: "45px",
                          width: "100%",
                        }}
                        id="appKey"
                      />
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          let key = $("#appKey").val();
                          if (key == appKey) {
                            localStorage.setItem("key", 1);
                            $("#alert").hide();
                            window.location.reload();
                          } else {
                            $("#alert").show();
                          }
                        }}
                      >
                        Submit
                      </Button>
                      <Alert
                        variant="danger"
                        style={{
                          marginTop: "10px",
                          padding: "5px 40px",
                          fontSize: "12px",
                          display: $("#appKey").val() ? "block" : "none",
                        }}
                        id="alert"
                      >
                        Incorrect Access key
                      </Alert>
                    </form>
                  </div>
                )}
              </>
            ) : (
              <>
                {/* Screen Saver */}
                {screenSaver == 1 && showScreensaver ? (
                  <div className="scsever">
                    <OwlCarousel options={options}>
                      {lsState.posterIds && lsState.posterIds.length > 0
                        ? lsState.posterIds.map((posterId) => {
                            var backImage = "";
                            if (posterId.indexOf("clients") > -1) {
                              backImage = myConfig.externalFileUrl + posterId;
                            } else {
                              backImage =
                                myConfig.externalFileUrl +
                                "clients/" +
                                clientId +
                                "/" +
                                eventId +
                                "/submissions/" +
                                posterId +
                                "/poster/poster_thumbs/poster.jpg";
                            }

                            return (
                              <div onMouseMove={changeDefOver} className="item">
                                <img
                                  src={backImage}
                                  alt="Powered by evexus"
                                />
                              </div>
                            );
                          })
                        : ""}
                    </OwlCarousel>
                  </div>
                ) : (
                  ""
                )}
                {/* Screen Saver */}
                <FadeIn>
                  <div className="App" style={backGroundStyle}>
                    <Router>
                      <div className="HeaderNav">
                        <Header
                          bannerImage={bannerImage}
                          headerDesign={headerDesign}
                          headerText={headerText}
                          textColor={textColor}
                          textBackgroundColor={textBackgroundColor}
                          sponsorByText={sponsorByText}
                          imageBackgroundColor={navFooterColor}
                        />
                        {navTop}
                      </div>
                      {footerUp}
                      <Switch>
                        <Route
                          exact
                          path="/"
                          component={() => (
                            <Index
                              theme_background_image={theme_background_image}
                              showSingleThemePoster={showSingleThemePoster}
                            />
                          )}
                        />
                        <Route
                          path="/posters"
                          component={() => (
                            <Poster
                              whiteLabel={whiteLabel}
                              defaultSort={defaultSort}
                            />
                          )}
                        />
                      </Switch>
                      {navDown}
                      {footerDown}
                    </Router>
                  </div>
                </FadeIn>
                <ScrollButton ScrollButtonColor={footerStyle} />
                <span style={{ display: "none" }}>
                  You will be auto logged out in{" "}
                  <span id="SecondsUntilExpire"></span> seconds.
                </span>
              </>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              fontSize: "20px",
              fontWeight: "500",
              backgroundColor: "#b3b2b1",
              flexDirection: "column",
              display: appStatus ? "flex" : "none",
            }}
          >
            <img
              src="/images/ePresenter.png"
              style={{ height: "10%", marginBottom: "10px" }}
            />

            <div>Digital Posters are not active</div>
          </div>
        )}
      </>
    </Fragment>
  );
}

export default App;
