import React, { useEffect } from "react";
import "./PosterPdf.scss";
// import { RiFullscreenFill } from 'react-icons/ri';

import { ScrollMode } from "@react-pdf-viewer/core";

import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";

import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

// import DisableScrollPlugin from "./DisableScrollPlugin";
import { Dropdown } from "react-bootstrap";

function PosterPdf() {
  const pdfURL = localStorage.getItem("pdfURL") || "";
  
  const bodyscrollremove=()=>{
    document.body.style.overflow = 'hidden' ;
    }
    useEffect(()=> {
      if(localStorage.getItem("pdfURL"))
        bodyscrollremove();
    },[localStorage.getItem("pdfURL")]) 

  // const scrollModePluginInstance = scrollModePlugin();
  // const { SwitchScrollMode } = scrollModePluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, Zoom, ZoomInButton, ZoomOutButton } =
    zoomPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();

  // const disableScrollPluginInstance = DisableScrollPlugin();

  const {
    CurrentPageLabel,
    CurrentPageInput,
    NumberOfPages,
    GoToPreviousPage,
    GoToNextPageButton,
    GoToNextPage,
  } = pageNavigationPluginInstance;

  return (
    <div className="pdfpreviewroot">
      <div className="rpv-core__viewer pdf-preview-container">
        <div className="page-navigate-btns prev-page-btn">
          {/* Button to go to the previous page */}
          <GoToPreviousPage />
        </div>
        <div className="page-navigate-btns next-page-btn">
          {/* Button to go to the next page */}
          <GoToNextPageButton />
        </div>
        <div className="viewer-container">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <Viewer
              fileUrl={pdfURL}
              // fileUrl="http://posterpdf-c86609.uatnewepresenter.espeaker.com.au/clients/68/657/submissions/128604/poster.pdf"
              // fileUrl="https://dx96ilnwgfdl0.cloudfront.net/1667573132512Test%20PPT%20landescape%20%282%29.pdf"

              scrollMode={ScrollMode.Vertical}
              plugins={[
                // scrollModePluginInstance,
                zoomPluginInstance,
                pageNavigationPluginInstance,
                // disableScrollPluginInstance,

              ]}
              defaultScale={SpecialZoomLevel.PageFit}
              // scrollMode={ScrollMode.Horizontal}
              onLoadError={console.error}
            />
          </Worker>
        </div>

        <div className="pdf-button-container">
          <div className="btn-foot-mobile prev-btn-mobile">
            <GoToPreviousPage>
              {(props) => (
                <button
                  className="navigate-btns"
                  // style={{
                  //     ...
                  // }}
                  // It will be disabled if the current page is the first page
                  disabled={props.isDisabled}
                  onClick={props.onClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.974"
                    height="53.948"
                    viewBox="0 0 30.974 53.948"
                  >
                    <path
                      id="Icon_feather-chevron-left"
                      data-name="Icon feather-chevron-left"
                      d="M34.817,51.635,13.5,30.317,34.817,9"
                      transform="translate(-9.5 -3.343)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                    />
                  </svg>
                </button>
              )}
            </GoToPreviousPage>
          </div>
          {/* <div className="fllscrn-btn">
            <EnterFullScreen>
              {(props) => (
                <button
                  style={{
                    // backgroundColor: "#357edd",
                    border: "none",
                    // borderRadius: "4px",
                    // color: "#ffffff",
                    // cursor: "pointer",
                    // padding: "8px",
                  }}
                  onClick={props.onClick}
                >
                  <RiFullscreenFill />
                </button>
              )}
            </EnterFullScreen>
            <EnterFullScreenButton />
          </div> */}
          <div className="zoom-control-buttons page-count">
            <CurrentPageLabel>
              {(props) => (
                <span>
                  Page: {`${props.currentPage + 1} of ${props.numberOfPages}`}
                </span>
              )}
            </CurrentPageLabel>
          </div>
          <div className="zoom-control-buttons">
            <ZoomOutButton />
          </div>
          <div className="zoom-control-buttons">
            {/* <ZoomPopover /> */}
            {/* <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} /> */}
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <CurrentScale>
                  {(props) => <>{`${Math.round(props.scale * 100)}%`}</>}
                </CurrentScale>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Zoom>
                    {(props) => (
                      <div
                        // style={{
                        //     ...
                        // }}
                        onClick={() =>
                          props.onZoom(SpecialZoomLevel.ActualSize)
                        }
                      >
                        Actual size
                      </div>
                    )}
                  </Zoom>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Zoom>
                    {(props) => (
                      <div
                        // style={{
                        //     ...
                        // }}
                        onClick={() => props.onZoom(SpecialZoomLevel.PageFit)}
                      >
                        Page Fit
                      </div>
                    )}
                  </Zoom>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Zoom>
                    {(props) => (
                      <div
                        // style={{
                        //     ...
                        // }}
                        onClick={() => props.onZoom(SpecialZoomLevel.PageWidth)}
                      >
                        Page Width
                      </div>
                    )}
                  </Zoom>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="zoom-control-buttons">
            <ZoomInButton />
          </div>
          <div className="page-navigate-desktop">
            {/* <CurrentPageLabel>
              {(props) => (
                <span>
                  Page: {`${props.currentPage + 1} of ${props.numberOfPages}`}
                </span>
              )}
            </CurrentPageLabel> */}
            {/* <span><CurrentPageInput /> / <NumberOfPages /></span> */}
            <div className="zoom-control-buttons">
              <GoToPreviousPage>
                {(props) => (
                  <button
                    className="navigate-btns"
                    // style={{
                    //     ...
                    // }}
                    // It will be disabled if the current page is the first page
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30.974"
                      height="53.948"
                      viewBox="0 0 30.974 53.948"
                    >
                      <path
                        id="Icon_feather-chevron-left"
                        data-name="Icon feather-chevron-left"
                        d="M34.817,51.635,13.5,30.317,34.817,9"
                        transform="translate(-9.5 -3.343)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="8"
                      />
                    </svg>
                    Previous
                  </button>
                )}
              </GoToPreviousPage>
            </div>
            <div className="zoom-control-buttons">
              <GoToNextPage>
                {(props) => (
                  <button
                    className="navigate-btns"
                    // style={{
                    //     ...
                    // }}
                    // It will be disabled if we are already at the last page

                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  >
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30.974"
                      height="53.948"
                      viewBox="0 0 30.974 53.948"
                    >
                      <path
                        id="Icon_feather-chevron-right"
                        data-name="Icon feather-chevron-right"
                        d="M13.5,51.635,34.817,30.317,13.5,9"
                        transform="translate(-7.843 -3.343)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="8"
                      />
                    </svg>
                  </button>
                )}
              </GoToNextPage>
            </div>
          </div>
          {/* <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} /> */}
          <div className="btn-foot-mobile next-btn-mobile">
            <GoToNextPage>
              {(props) => (
                <button
                  className="navigate-btns"
                  // style={{
                  //     ...
                  // }}
                  // It will be disabled if we are already at the last page

                  disabled={props.isDisabled}
                  onClick={props.onClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.974"
                    height="53.948"
                    viewBox="0 0 30.974 53.948"
                  >
                    <path
                      id="Icon_feather-chevron-right"
                      data-name="Icon feather-chevron-right"
                      d="M13.5,51.635,34.817,30.317,13.5,9"
                      transform="translate(-7.843 -3.343)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                    />
                  </svg>
                </button>
              )}
            </GoToNextPage>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PosterPdf;
